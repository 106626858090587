export const brand0 = require('./brand0.png');
export const brand1 = require('./brand1.png');
export const brand2 = require('./brand2.png');
export const brand3 = require('./brand3.png');
export const brand4 = require('./brand4.png');
export const brand5 = require('./brand5.png');
export const brand6 = require('./brand6.png');
export const brand7 = require('./brand7.png');
export const brand8 = require('./brand8.png');
export const brand9 = require('./brand9.png');
export const brand10 = require('./brand10.png');
export const brand11 = require('./brand11.png');
