// @ts-ignore
import React, { ForwardedRef, forwardRef } from 'react';
import { css } from '@emotion/css';
import useTheme from 'evcharging/hooks/useTheme';
import { Theme } from 'evcharging/app/themes';
import useI18n from 'evcharging/hooks/useI18n';
import Spacer from 'evcharging/components/Spacer';
import Paragraph from 'evcharging/components/Paragraph';
import Container from 'evcharging/components/Container';
import LightButton from 'evcharging/components/LightButton';
import Responsible from 'evcharging/components/Responsible';
import LinkButton from 'evcharging/components/LinkButton';
import { EV_PROJECTS_URL } from 'evcharging/app/seo/urls';

interface VideoProps {
  src: string;
  openFullVideo: () => void;
}

const EvcVideo = forwardRef((props: VideoProps, ref: ForwardedRef<HTMLVideoElement>) => {
  const { src, openFullVideo } = props;
  const theme = useTheme();
  const styles = createStyles(theme);
  const { t } = useI18n();

  return (
    <div className={styles.root}>
      <div className={styles.gradient}>
        <Container>
          <div className={styles.content}>
            <h1 className={styles.title}>
              {t('home.revolutionizing-the')}<br/>
              {t('home.ev-charging')}
            </h1>
            <Spacer value={20} />
            <Paragraph color={'light'} maxWidth={1100} size={'large'}>
              {t('home.welcome')}
            </Paragraph>
            <Spacer value={48} />
            <Responsible fractions={[55, 37]} maxWidth={450}>
              {/*<LinkButton href={EV_PROJECTS_URL}>{t('home.explore-our')}</LinkButton>*/}
              <LightButton onClick={openFullVideo}>{t('home.watch-full-video')}</LightButton>
            </Responsible>
          </div>
        </Container>
      </div>

      <video ref={ref} preload="auto" loop autoPlay playsInline muted>
        <source src={src} type={'video/mp4'} />
      </video>
    </div>
  );
});

const createStyles = (theme: Theme) => ({
  root: css`
    height: 600px;
    position: relative;
    & > video {
      width: 100%;
      height: 600px;
      object-fit: cover;
    }
  `,
  gradient: css`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: linear-gradient(360deg, #131c21 0%, rgba(26, 32, 36, 0.62) 100%);
  `,
  title: css`
    font-weight: 900;
    font-size: 64px;
    line-height: 78px;
    color: #F9C106;
    max-width: 800px;
    ${theme.breakpoints.mobile.down} {
      font-size: 32px;
      line-height: 40px;
    }
  `,
  content: css`
    padding: 97px 0 45px;
    ${theme.breakpoints.mobile.down} {
      padding: 97px 0 45px;
    }
  `,
});

export default EvcVideo;
